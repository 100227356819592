import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[...not-found]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"cyrillic\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[...not-found]/layout.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Vignette/Vignette.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/src/hooks/useTheme.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WithRedux"] */ "/vercel/path0/src/providers/WithRedux.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.scss");
